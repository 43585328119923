import { useState, useMemo } from 'react'
import { API } from 'aws-amplify'
import Grid from '@mui/material/Grid';
import { DataGridPremium, GridColDef, useGridApiRef, GridRowModesModel, GridRowModes, GridRowParams, GridRowModel, GridEventListener, GridRowEditStopReasons, GridCallbackDetails } from '@mui/x-data-grid-premium';
import { Paper, SvgIcon, IconButton, CircularProgress, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../common/Components/PageHeader';
import { Status, useFetch } from '../../hooks/useFetch';
import { useParams } from 'react-router-dom';
import { DynamoDbObject } from '@aviation/catering-common';
import StyledBox from '../../common/Components/StyledBox'
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import { ReactComponent as SettingsIcon } from '../../icons/settings.svg';
import { toast } from "react-toastify";
import { SourceMarketFields } from '@aviation/catering-masterdata-sdk'
import SourceMarketCreateDialog from './SourceMarketCreateDialog';
import SourceMarketsAirlineList from './SourceMarketsAirlineList'
import React from 'react';

function SourceMarkets() {
    const apiRef = useGridApiRef();
    const { t } = useTranslation();
    const { clientCode } = useParams();
    const { status, data = [] } = useFetch<Array<SourceMarketFields & DynamoDbObject>>(`/api/masterdata/sourcemarkets`);

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<SourceMarketFields & DynamoDbObject | undefined>(undefined);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentParent, setCurrentParent] = useState<SourceMarketFields & DynamoDbObject | undefined>(undefined);
    const [showAirlineList, setShowAirlineList] = useState(false);
    const rowId = (row: any): string => {
        return `${row.PK}#${row.RK}`;
    }

    const rows = useMemo(() => {
        return data.map(o => { return { ...o, DistinctSsrCount: o.DistinctSsrCount ?? true, id: rowId(o) } }).sort((a, b) => (a.RK ?? '') < (b.RK ?? '') ? -1 : 1);
    }, [data])

    const cancelAirlineList = () => {
        setShowAirlineList(false);

        // Reset item, to clear grid in AirlineList, otherwise it might happen
        // that the grid will try to load id's that are not present anymore (when users taps another source market)
        setCurrentParent({ PK: '', RK: '', Airlines: [] });
    }
    const columns: GridColDef[] = [
        { field: 'ClientCode', headerName: 'Code', minWidth: 150, editable: false },
        { field: 'ClientName', headerName: 'Name', minWidth: 150, editable: true },
        { field: 'DistinctSsrCount', headerName: 'Distinct Meals', minWidth: 150, editable: true, type: 'boolean' },
        {
            field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
                const isInEditMode = (rowModesModel[rowId(row)]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
                const isLoading = row.IsLoading ?? false;

                if (isInEditMode)
                    return [
                        <IconButton color="success" onClick={handleSaveClick(row)}>
                            <SvgIcon component={CheckmarkIcon} inheritViewBox />
                        </IconButton>,
                        <IconButton color="error" onClick={handleCancelClick(rowId(row))}>
                            <SvgIcon component={CrossIcon} inheritViewBox />
                        </IconButton>
                    ]
                else if (!isLoading)
                    return [
                        <Tooltip title="Settings">
                            <IconButton color="primary" onClick={() => { setCurrentParent(row); setShowAirlineList(true); }}>
                                <SvgIcon component={SettingsIcon} inheritViewBox />
                            </IconButton>
                        </Tooltip>,
                        <IconButton color="error" onClick={() => confirmDeletion(row)}>
                            <SvgIcon component={TrashIcon} inheritViewBox />
                        </IconButton>
                    ]
                else if (isLoading)
                    return (
                        <CircularProgress size={19} />
                    )
            }
        }
    ];

    const handleRowModesModelChange = (model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else
            setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });

    };

    const handleSaveClick = (item: any) => () => {
        setRowModesModel({ ...rowModesModel, [rowId(item) ?? '']: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id: any) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    const handleDeleteItem = async () => {
        setShowDeleteConfirmation(false);

        if (deleteItem !== undefined) {
            apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: true }]);

            const init = {
                body: {},
                headers: {}
            };

            try {
                await API.del('api', `/api/masterdata/sourcemarkets/${deleteItem.ClientCode}`, init);
                apiRef.current.updateRows([{ id: rowId(deleteItem), _action: 'delete' }]);
                toast.success(`Source market deleted successfully.`);
                return true;
            } catch (e) {
                console.error(e);
                apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: false }]);
                toast.error(`An error occurred while deleting source market`);
                return false;
            } finally {
                setDeleteItem(undefined);
            }
        }
    };

    const updateItem = async (item: SourceMarketFields & DynamoDbObject) => {
        let code = item.ClientCode;

        const init = {
            body: item,
            headers: {}
        };

        try {
            await API.put('api', `/api/masterdata/sourcemarkets/${code}`, init);
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    const confirmDeletion = (item: any) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };

    const onCreateItem = async (code: string, name: string) => {
        setShowCreateDialog(false);
        setLoading(true);

        const item: SourceMarketFields & DynamoDbObject = {
            ClientCode: code,
            ClientName: name,
            DistinctSsrCount: true,
            Airlines: [],
            PK: 'SourceMarket',
            RK: code.trim().toUpperCase()
        };

        // Define id and airline for grid usage
        (item as any).id = rowId(item);

        if (await updateItem(item)) {
            setLoading(false);
            apiRef.current.updateRows([item]);
            toast.success(`Source market created successfully`);
        } else {
            setLoading(false);
            toast.error(`An error occurred while creating source market`);
        }
    }

    const processRowUpdate = (row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };

        updateItem(row as SourceMarketFields & DynamoDbObject).then(success => {
            apiRef.current.updateRows([{ id: rowId(row), IsLoading: false }]);

            if (success)
                toast.success(`Changes to source market saved successfully.`);
            else
                toast.error(`An error occurred while saving source market`);
        })

        return updatedRow;
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <PageHeader title={t('Source Markets')}>
                    <Button variant="contained" onClick={() => setShowCreateDialog(true)}>Create</Button>
                </PageHeader>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', minHeight: '100px' }} elevation={3}>
                    <StyledBox>
                        <DataGridPremium
                            apiRef={apiRef}
                            autoHeight
                            rows={rows}
                            columns={columns}
                            editMode='row'
                            pagination
                            loading={status === Status.Fetching || status === Status.Idle || loading}
                            getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 1 ? `tui-grid-alternate-row` : '' }}
                            isCellEditable={() => true}
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={(m, d) => handleRowModesModelChange(m, d)}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}
                        />
                    </StyledBox>
                    <SourceMarketCreateDialog open={showCreateDialog} cancelAction={() => setShowCreateDialog(false)} createAction={onCreateItem} />
                    <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Delete Source Market?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Do you want to delete this source market? You can't undo this action.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                            <Button onClick={handleDeleteItem} color="error">Delete</Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Grid>

            { currentParent && <SourceMarketsAirlineList clientCode={currentParent.ClientCode ?? clientCode ?? 'BNX'} open={showAirlineList} parent={currentParent} cancelAction={() => {cancelAirlineList()}} /> }
        </Grid>
    );
}

export default SourceMarkets;