import { useState, useMemo, useCallback } from 'react'
import { API } from 'aws-amplify';
import { Paper, Button, CircularProgress, SvgIcon, IconButton, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DataGridPremium, GridColDef, GridRowModesModel, GridRowModes, GridRowParams, useGridApiRef, GridEventListener, GridRowEditStopReasons, GridRowModel, GridCallbackDetails, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../common/Components/PageHeader';
import { Status, useFetch } from '../../hooks/useFetch';
import { useParams } from 'react-router-dom';
import { DynamoDbObject } from '@aviation/catering-common';
import { AirlineFields, LoadingPlanPerDestinationFields, LoadingPlanFields, CatererFields } from '@aviation/catering-masterdata-sdk';
import StyledBox from '../../common/Components/StyledBox'
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import {toast} from "react-toastify";
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import LoadingPlanPerDestDetails from './LoadingPlanPerDestinationDetails';
import LoadingPlanPerDestCreateDialog from './LoadingPlanPerDestinationCreateDialog';
import { ulid } from 'ulidx';

function LoadingPlanPerDestination() {
    const apiRef = useGridApiRef();
    const { t } = useTranslation();
    const { clientCode } = useParams();
    const { status, data = [] } = useFetch<Array<LoadingPlanPerDestinationFields & DynamoDbObject>>(`/api/masterdata/loadingplanperdestination/${clientCode}`);
    const { data: airlineData = [] } = useFetch<Array<AirlineFields & DynamoDbObject>>(`/api/masterdata/airline/${clientCode}`); 
    const { data: loadingPlanData = [] } = useFetch<Array<LoadingPlanFields & DynamoDbObject>>(`/api/masterdata/loadingplan/${clientCode}`);
    const { data: catererData = [] } = useFetch<Array<CatererFields & DynamoDbObject>>(`/api/masterdata/caterer`);
    const { data: haulTypeData = [] } = useFetch<Array<CatererFields & DynamoDbObject>>(`/api/masterdata/haultype/${clientCode}`);

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<LoadingPlanPerDestinationFields & DynamoDbObject | undefined>(undefined);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showDetailsAnchor, setShowDetailsAnchor] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentParent, setCurrentParent] = useState<any>(undefined);

    const rowId = (row : any) => {
        return `${row.PK}#${row.RK}`;
    }
    
    const airlineList = useMemo(() => {
        return airlineData.map(o => { return {label: (o as any).Description ?? '', value: o.RK } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [airlineData])

    const catererList = useMemo(() => {
        return catererData.map(o => { return {label: o.Name ?? '', value: o.RK } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [catererData])

    const loadingPlanList = useMemo(() => {
        return loadingPlanData.map(o => { return {label: o.Name ?? '', value: o.RK.split('#')[1], airline: o.RK.split('#')[0] } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [loadingPlanData])

    const haulTypeList = useMemo(() => {
        return haulTypeData.map(o => { return {label: o.Name ?? '', value: o.RK.split('#')[1], airline: o.RK.split('#')[0] } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [haulTypeData])

    const waveList = useMemo(() => {
        return [
            {label: "1st", value: 1 },
            {label: "2nd", value: 2 },
            {label: "3rd", value: 3 }]
    }, []);

    const rows = useMemo(() => {
        if (!data) return [];
        const result = (data as any[]).reduce((a, b) => [
            ...a,
            {
                ...b,
                id: rowId(b),
                Al: b.RK.split('#')[0],
                Dest: b.RK.split('#')[1],
                Entries: b.Entries.map((o : any) => { return {...o, EntryId: o.EntryId ?? ulid().toString()}})
            }
        ], []);

        return result.filter((o : any) => o.Dest !== '-');
    }, [data])

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    const confirmDeletion = (item : any) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };

    const handleDeleteItem = async () => {
        setShowDeleteConfirmation(false);

        if(deleteItem !== undefined) {
            apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: true }]);

            const init = {
                body: {},
                headers: {}
            };
    
            try {
                await API.del('api', `/api/masterdata/loadingplanperdestination/${clientCode}/${(deleteItem as any).Al}/${deleteItem.RK.split('#')[1]}`, init);
                apiRef.current.updateRows([{ id: rowId(deleteItem), _action: 'delete' }]);
                toast.success(`Loading plan deleted successfully.`);
                return true;
            } catch(e) {
                console.error(e);
                apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: false }]);
                toast.error(`An error occurred while deleting loading plan`);
                return false;
            } finally {
                setDeleteItem(undefined);
            }
        }
    };

    const handleSaveClick = (item : any) => () => {
        setRowModesModel({...rowModesModel, [item.id ?? '']: {mode: GridRowModes.View }}); 
    };
    
    const handleCancelClick = (id : any) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
      };

    const handleRowModesModelChange = useCallback((model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
        // eslint-disable-next-line
      }, [data]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
        // eslint-disable-next-line
    }, [data]);

    const processRowUpdate = useCallback((row: GridRowModel) => {
        return row;
        // const updatedRow = { ...row, isNew: false, IsLoading: true };
        
        // updateItem(row as AirlineFields & DynamoDbObject).then(success => {
        //     apiRef.current.updateRows([{ id: rowId(row), IsLoading: false }]);

        //     if(success) 
        //         toast.success(`Changes to airline saved successfully.`);
        //     else
        //         toast.error(`An error occurred while saving airline`);
        // })
        
        // return updatedRow;
        // eslint-disable-next-line
    }, [data]);

    const createForecastEntry = (airline : string, dest : string) => {
        setShowCreateDialog(false);
        setLoading(true);

        const item : LoadingPlanPerDestinationFields & DynamoDbObject = {
            PK: `${clientCode}#LoadingPlanPerDest`,
            RK: `${airline}#${dest.toUpperCase()}`,
            Entries: []
        }

        updateConfiguration(item).then((result : boolean) => {
            setLoading(false);

            if(result) {
                (item as any).id = rowId(item);
                (item as any).Al = airline;
                (item as any).Dest = dest;
                apiRef.current.updateRows([item]);
                toast.success('Loading Plan per Destination created successfully.');
            }
            else
                toast.error('An error occurred while creating loading plan per destination');
        });
    }

    const updateConfiguration = async (item : any) => {
        const init = {
            body: item,
            headers: {}
        };

        try {
            await API.put('api', `/api/masterdata/loadingplanperdestination/${clientCode}/${item.RK.split('#')[0]}/${item.RK.split('#')[1]}`, init);
            return true;
        } catch(e) {
            console.error(e);
            return false;
        } 
    }

    const columns: GridColDef[] = [
        { field: 'Al', headerName: 'Airline', minWidth: 150, editable: false, type: 'singleSelect', valueOptions: airlineList },
        { field: 'Dest', headerName: 'Dest', minWidth: 70, editable: false},
        { field: 'Entries', headerName: 'Items', minWidth: 80, valueGetter: (params) => params.row.Entries.length, editable: false },
        { field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
            const isInEditMode = (rowModesModel[row.id]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
            const isLoading = row.IsLoading ?? false;
    
            if(isInEditMode) 
                return [
                    <IconButton color="success" onClick={handleSaveClick(row)}>
                        <SvgIcon component={CheckmarkIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={handleCancelClick(row.id)}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(!isLoading)
                if(!isInEditMode) return [
                    <IconButton color="primary" onClick={() => { setCurrentParent(row); setShowDetailsAnchor(true); }}>
                        <SvgIcon component={EditIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={() => confirmDeletion(row)}>
                        <SvgIcon component={TrashIcon} inheritViewBox />
                    </IconButton>
                    
                ]
                else
                    return ( 
                        <IconButton color="error" onClick={() => confirmDeletion(row)}>
                            <SvgIcon component={TrashIcon} inheritViewBox />
                        </IconButton>
                    )
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];

    const getTreeDataPath = (row : any) => {
        return row.id.split('.');
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <PageHeader title={t('Loading Plan per Destination')}>
                    <Button variant="contained" onClick={() => setShowCreateDialog(true)}>Create</Button>
                </PageHeader>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', minHeight: '100px' }} elevation={3}>
                    <StyledBox>
                    <DataGridPremium
                        apiRef={apiRef}
                        treeData
                        autoHeight 
                        rows={rows}
                        columns={columns}
                        editMode="row"
                        getTreeDataPath={getTreeDataPath}
                        // getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 1 ? `tui-grid-alternate-row` : ''}}
                        getRowClassName={(params) => { return params.row.Parent === false ? `tui-grid-leg-row` : 'tui-grid-route-row'}}
                        // pagination
                        loading={status === Status.Fetching || status === Status.Idle || loading}
                        isCellEditable={(params) => true }

                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        
                        groupingColDef={{
                            headerName: ' ',
                            valueGetter: (params: GridValueGetterParams) => '',
                            width: 50,
                            resizable: false,
                            hideable: false,                
                        }}
                    />
                    </StyledBox>
                    { showCreateDialog && (<LoadingPlanPerDestCreateDialog open={showCreateDialog} airlineList={airlineList} cancelAction={() => setShowCreateDialog(false)} createAction={createForecastEntry}/>)}
                    <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Delete destination configuration?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to delete this destination configuration? You can't undo this action.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                            <Button onClick={handleDeleteItem} color="error">Delete</Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Grid>
            { currentParent && <LoadingPlanPerDestDetails open={showDetailsAnchor} parent={currentParent} haulTypeList={haulTypeList.filter(o => o.airline === currentParent.Al)} loadingPlanList={loadingPlanList.filter(o => o.airline === currentParent.Al)} catererList={catererList} waveList={waveList} cancelAction={() => {setShowDetailsAnchor(false);}} updateAction={() => {}} />}
        </Grid>
    );
}

export default LoadingPlanPerDestination;